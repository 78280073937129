<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="d-flex flex-column align-center overflow-y-auto mt-20">
      <div class="content-container align-center">
        <photo-shooter
          ref="shooter"
          @photoReady="photoReady = $event"
        ></photo-shooter>
        <div class="d-flex justify-center">
          <v-btn
            v-if="photoReady"
            :color="WEBAPPPRO_BUTTON_COLOR_1"
            :disabled="uploading"
            @click="saveAndContinue"
            :loading="uploading"
            :style="{
              color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
              borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
              'text-transform': 'none',
              marginBottom: '40px',
              padding: '25px 100px',
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
              'font-weight': 'bold',
              'line-height': '18px',
              'letter-spacing': '0em',
              'text-align': 'center',
              width: '327px',
              height: '52px',
            }"
            >Continuer
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoShooter from '@/components/PhotoShooter'
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'Photo',
  data() {
    return {
      photoReady: false,
      uploading: false,
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
    }
  },
  components: {
    PhotoShooter,
  },
  methods: {
    async saveAndContinue() {
      this.uploading = true
      this.$refs.shooter.stopCameraStream()
      this.$refs.shooter.save()
      if (localStorageService.getValue('rejectStatus') === 'rejected_photo') {
        //order save
        const orderFormData = JSON.stringify({
          status: 'pending_verification',
          photo: {
            status: 'pending_verification',
            newPhotoUid: localStorageService.getValue('photoUid'),
          },
        })
        requestService
          .post(
            '/document/' +
              localStorageService.getValue('documentId') +
              '/update',
            orderFormData,
            {},
            {
              'content-type': 'application/json',
              shortlive_api_key:
                localStorageService.getValue('shortliveApiKey'),
            },
          )
          .then(() => {
            this.$router.push({ name: 'Updated' })
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              'Erreur lors de la création de la commande.',
            )
            this.$router.push({ name: 'NotAuthorized' })
          })
      } else {
        this.$router.push({ name: 'Signature' })
        this.uploading = false
      }
    },
  },
  mounted() {
    if (localStorageService.getValue('shortliveApiKey') === undefined) {
      this.$router.push({ name: 'NotAuthorized' })
    } else if (localStorageService.getValue('photoUrl') === undefined) {
      this.$router.push({ name: 'ShootPhoto' })
    }
  },
}
</script>
<style scoped lang="scss">
.background-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
</style>
