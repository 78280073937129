import Vue from 'vue'
import VueRouter from 'vue-router'
import ShootPhoto from '../views/ShootPhoto.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'NotAuthorized',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/NotAuthorized.vue'),
  },
  {
    path: '/:uuid/agence/authorization-request',
    name: 'Authorization',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Welcome" */
        '../views/Partner/Authorization.vue'
      ),
  },
  {
    path: '/:uuid/agence/authorization-request',
    name: 'AuthorizationAgenceProExtern',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () => import('../views/Auth/AuthorizationProExtern.vue'),
  },
  {
    path: '/agence/:partner_uid/:agence_id/:agence_uid',
    name: 'Agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () => import('../views/Auth/Agence.vue'),
  },
  {
    path: '/order/photo',
    name: 'ShootPhoto',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: ShootPhoto,
  },
  {
    path: '/order/signature',
    name: 'Signature',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(/* webpackChunkName: "signature" */ '../views/Signature.vue'),
  },
  {
    path: '/payment',
    name: 'Payment',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Payment.vue'),
  },
  {
    path: '/authentication-agence/:partner_uid',
    name: 'Authentication-agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        '../views/Partner/Authentication.vue'
      ),
  },
  {
    path: '/home-agence/:partner_uid',
    name: 'Home-agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Partner/Home.vue'),
  },
  {
    path: '/orders-agence/:partner_uid',
    name: 'Orders-agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Partner/Orders.vue'),
  },
  {
    path: '/waiting-orders-agence/:partner_uid',
    name: 'Waiting-orders-agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        '../views/Partner/WaitingOrders.vue'
      ),
  },
  {
    path: '/new-order-agence/:partner_uid/:agence_id/:agence_uid',
    name: 'New-order-agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () => import('../views/Partner/NewOrder.vue'),
  },
  {
    path: '/wallet-agence/:partner_uid',
    name: 'Wallet-agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Partner/Wallet.vue'),
  },
  {
    path: '/payment-wallet-agence/:partner_uid',
    name: 'Payment-wallet-agence',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Payment.vue'),
  },
  {
    path: '/not-authorized-agence/:partner_uid',
    name: 'NotAuthorized',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        '../views/Partner/NotAuthorized.vue'
      ),
  },
  {
    path: '/authentication/:id',
    name: 'Authentication',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Authentication" */
        '../views/Authentication.vue'
      ),
  },
  {
    path: '/paymentCancel',
    name: 'PaymentCancel',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentCancel" */
        '../views/Bye/PaymentCancel.vue'
      ),
  },
  {
    path: '/paymentSuccess',
    name: 'PaymentSuccess',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccess" */
        '../views/Bye/PaymentSuccess.vue'
      ),
  },
  {
    path: '/updated',
    name: 'Updated',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccess" */
        '../views/Bye/Updated.vue'
      ),
  },
  {
    path: '/not-allow',
    name: 'AlertAgency',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccess" */
        '../views/AlertAgency.vue'
      ),
  },
  {
    path: '/start-new-order',
    name: 'New-order',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(/* webpackChunkName: "Welcome" */ '../views/Welcome/Welcome.vue'),
  },
  {
    path: '/photo',
    name: 'WelcomeRedoPhoto',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Welcome" */
        '../views/Welcome/WelcomeRedoPhoto.vue'
      ),
  },
  {
    path: '/signature',
    name: 'WelcomeRedoSignature',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Welcome" */
        '../views/Welcome/WelcomeRedoSignature.vue'
      ),
  },
  {
    path: '/accueil',
    name: 'Accueil',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Welcome" */
        '../views/Partner/Accueil.vue'
      ),
  },

  {
    path: '/taking-good-photo/:partner_uid',
    name: 'TipsPhoto',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ '../views/Partner/TipsPhoto.vue'
      ),
  },
  {
    path: '/treatment-photo/:partner_uid',
    name: 'TreatmentPhoto',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ '../views/Partner/TreatmentPhoto.vue'
      ),
  },
  {
    path: '/end/:partner_uid',
    name: 'Final',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Final.vue'),
  },
  {
    path: '/end',
    name: 'Final',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Final.vue'),
  },
  {
    path: 'welcome/:partner_uid',
    name: 'Welcome',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(/* webpackChunkName: "Welcome" */ '../views/Welcome/Welcome.vue'),
  },
  {
    path: '/scan-qrCode',
    name: 'ScanQrcode',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Authentication" */
        '../views/ScanQrcode.vue'
      ),
  },
  {
    path: '/addOfferEphoto',
    name: 'addOfferEphoto',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Authentication" */
        '../views/Partner/AddOfferEphoto.vue'
      ),
  },
  {
    path: '/confirmation-email',
    name: 'ConfirmationEmail',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Authentication" */
        '../views/ConfirmationEmail.vue'
      ),
  },
  {
    path: '/verify-user',
    name: 'VerifyUser',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        '../views/Partner/VerifyUser.vue'
      ),
  },
  {
    path: '/choice',
    name: 'Choice',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        '../views/Partner/Choice.vue'
      ),
  },
  {
    path: '/generate-qrCode/:partner_uid',
    name: 'GenerateQrCode',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Authentication" */
        '../views/Partner/GenerateQrCode.vue'
      ),
  },
  {
    path: '/refresh-token/:partner_uid',
    name: 'RefreshToken',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        '../views/Partner/RefreshToken.vue'
      ),
  },
  {
    path: '/fiche-candidat/:id',
    name: 'FicheCandidat',
    meta: {
      NotAuthorizedRoute: 'NotAuthorized',
      allowRefreshToken: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Authentication" */
        '../views/Partner/FicheCandidat.vue'
      ),
  },
  {
    path: '/setting-agence/:partner_uid',
    name: 'Settings',
    meta: {
      NotAuthorizedRoute: 'NotAuthorizedPartner',
      allowRefreshToken: true,
    },
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Partner/Settings.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
